import { template as template_0e9fd8755dcf4fa7919b40bb59435c7c } from "@ember/template-compiler";
const FKText = template_0e9fd8755dcf4fa7919b40bb59435c7c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
