import { template as template_f68cb64ed39a48499cf122c5ee37084b } from "@ember/template-compiler";
const WelcomeHeader = template_f68cb64ed39a48499cf122c5ee37084b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
