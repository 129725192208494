import { template as template_28cb1b66cc404bbca1e36fe9cc050b71 } from "@ember/template-compiler";
const FKControlMenuContainer = template_28cb1b66cc404bbca1e36fe9cc050b71(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
