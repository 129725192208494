import { template as template_40ec786483fd4232a12d45a745b197fe } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_40ec786483fd4232a12d45a745b197fe(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
