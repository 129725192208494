import { template as template_6da5e09238654e6188f55c2d3c1bf6fb } from "@ember/template-compiler";
const FKLabel = template_6da5e09238654e6188f55c2d3c1bf6fb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
